import { useObserver } from 'mobx-react';
import st from '../../AdminFinalCheckView.module.scss';
import clsx from 'clsx';
import {toJS} from "mobx";
import {addCommas, percent} from '@common/module/replaceNumber';
import { Fragment } from 'react';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';
import Icon from '@comComponents/atoms/Icon/Icon';
import CapitalIncreaseStepVm from './CapitalIncreaseStepVm';

function CapitalIncreaseStep(props) {
    return useObserver(() => (
        <table className={st.lightTable}>
            <colgroup>
                {CapitalIncreaseStepVm.limitCompanyYn ?
                <>
                    <col width="80px"/>
                    <col width="350px"/>
                    <col/>
                    <col width="130px"/>
                </>
                :
                <>
                    <col width="120px"/>
                    <col />
                    <col width="130px"/>
                    <col width="130px"/>    
                </>
                }
                {CapitalIncreaseStepVm.limitCompanyYn && <col width="130px"/>}
                {CapitalIncreaseStepVm.limitCompanyYn && <col width="130px"/>}
            </colgroup>
            <thead>
                <tr>
                    <th></th>
                    <th style={{textAlign:"center"}}>인적사항</th>
                    <th style={{textAlign:"center"}}>발행방법</th>
                    <th style={{textAlign:"right"}}>투자금</th>
                    {CapitalIncreaseStepVm.limitCompanyYn && <th style={{textAlign:"right"}}>출자좌수</th>}
                    {CapitalIncreaseStepVm.limitCompanyYn && <th style={{textAlign:"right"}}>발행가액<br/>(자동계산)</th>}
                </tr>
            </thead>
            <tbody>
                {props.step.shareholders.length === 0 &&
                    <tr>
                        <td style={{textAlign:"center"}}>-</td>
                        <td style={{textAlign:"center"}}>-</td>
                        <td style={{textAlign:"center"}}>-</td>
                        <td style={{textAlign:"right"}}>-</td>
                        {CapitalIncreaseStepVm.limitCompanyYn && <td style={{textAlign:"right"}}>-</td>}
                        {CapitalIncreaseStepVm.limitCompanyYn && <td style={{textAlign:"right"}}>-</td>}
                    </tr>
                }
                {props.step.shareholders.map((shareholder, index) => {
                    let 유상증자Cnt = 0; let 가수금증자Cnt= 0;
                    if(shareholder?.유상증자) 유상증자Cnt = 1;
                    if(shareholder?.가수금증자) 가수금증자Cnt = 1;
                    return (
                        <Fragment key={index}>
                            <tr>
                                <td className={clsx(st.bottom, st.top)} rowSpan={유상증자Cnt+가수금증자Cnt+1} style={{paddingLeft:"0"}}>
                                    <div className={st.name}>
                                        <CopyBtn onClick={() => copyText(shareholder.koreanName)} className={st.addressCopyBtn} style={{textAlign:'left'}}>{shareholder.koreanName}</CopyBtn>
                                        {shareholder.englishName && 
                                            <div style={{marginBottom:'2px', fontSize:"13px"}}>
                                                <CopyBtn onClick={() => copyText(shareholder.englishName)} className={st.addressCopyBtn} style={{textAlign:'left'}}>{shareholder.englishName}</CopyBtn>
                                            </div>
                                        }
                                    </div>
                                    <div>{shareholder.existingShareholdersYn ? '기존 출자자' : '제3자'}</div>
                                </td>
                                <td className={clsx(st.bottom, st.top)} rowSpan={유상증자Cnt+가수금증자Cnt+1} style={{textAlign:"center"}}>
                                    {(shareholder.shareholderType === "FOREIGNER_COMPANY" || shareholder.shareholderType === "KOREAN_COMPANY") && 
                                        <div style={{marginBottom:"5px"}}>
                                            {shareholder.representatives.map((representative, index2) => (
                                                <div key={index2}>
                                                    {representative.position} <CopyBtn onClick={() => copyText(representative.koreanName)} className={st.addressCopyBtn}>{representative.koreanName}</CopyBtn>
                                                    {representative.englishName && 
                                                        <span>(<CopyBtn onClick={() => copyText(representative.englishName)} className={st.addressCopyBtn}>{representative.englishName}</CopyBtn>)</span>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {shareholder.shareholderType === "FUND_AND_INVESTMENT_ASSOCIATION" && 
                                        <div style={{marginBottom:"5px"}}>
                                            {shareholder.representatives.map((representative, index2) => (
                                                <div key={index2}>
                                                    대표 <CopyBtn onClick={() => copyText(representative.koreanName)} className={st.addressCopyBtn}>{representative.koreanName}</CopyBtn>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {shareholder.birth && 
                                        <div style={{marginBottom:"5px"}}><CopyBtn onClick={() => copyText(shareholder.birth)} className={st.addressCopyBtn}>{shareholder.birth}</CopyBtn></div>
                                    }
                                    {shareholder.englishName &&
                                        <div style={{marginBottom:"5px"}}>{shareholder.nationality}</div>
                                    }
                                    <div>
                                        {shareholder?.address !== null &&
                                            <CopyBtn onClick={() => copyText(shareholder?.koreanAddress)} className={st.addressCopyBtn}>{shareholder?.koreanAddress}</CopyBtn>
                                        }
                                        {shareholder.englishAddress && 
                                            <div>(<CopyBtn onClick={() => copyText(shareholder.englishAddress)} className={st.addressCopyBtn}>{shareholder.englishAddress}</CopyBtn>)</div>
                                        }
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                {CapitalIncreaseStepVm.limitCompanyYn && <td></td>}
                                {CapitalIncreaseStepVm.limitCompanyYn && <td></td>}
                            </tr>
                            {shareholder?.유상증자 &&
                                <tr className={clsx(가수금증자Cnt + 유상증자Cnt === 1 ? st.bottom : null, 유상증자Cnt === 1 ? st.top : null)}>
                                    <td style={{textAlign:"center"}}>일반 자본금 증액</td>
                                    <td style={{textAlign:"right"}}><CopyBtn onClick={() => copyText(addCommas(shareholder?.유상증자.investment))} className={st.addressCopyBtn}>{addCommas(shareholder?.유상증자.investment)}</CopyBtn> 원</td>
                                    {CapitalIncreaseStepVm.limitCompanyYn && <td style={{textAlign:"right"}}><CopyBtn onClick={() => copyText(addCommas(shareholder?.유상증자.number))} className={st.addressCopyBtn}>{addCommas(shareholder?.유상증자.number)}</CopyBtn> 주</td>}
                                    {CapitalIncreaseStepVm.limitCompanyYn && <td style={{textAlign:"right"}}><CopyBtn onClick={() => copyText(addCommas(shareholder?.유상증자.investment/ shareholder?.유상증자.number))} className={st.addressCopyBtn}>{addCommas(shareholder?.유상증자.investment/shareholder?.유상증자.number)}</CopyBtn> 원</td>}
                                </tr>
                            }
                            {shareholder?.가수금증자 &&
                                <tr className={clsx(((유상증자Cnt === 1 && 가수금증자Cnt === 1) || 가수금증자Cnt === 1) ? st.bottom : null, 가수금증자Cnt === 1 ? st.top : null)}>
                                    <td style={{textAlign:"center"}}>가수금증자</td>
                                    <td style={{textAlign:"right"}}><CopyBtn onClick={() => copyText(addCommas(shareholder?.가수금증자.investment))} className={st.addressCopyBtn}>{addCommas(shareholder?.가수금증자.investment)}</CopyBtn> 원</td>
                                    {CapitalIncreaseStepVm.limitCompanyYn && <td style={{textAlign:"right"}}><CopyBtn onClick={() => copyText(addCommas(shareholder?.가수금증자.number))} className={st.addressCopyBtn}>{addCommas(shareholder?.가수금증자.number)}</CopyBtn> 주</td>}
                                    {CapitalIncreaseStepVm.limitCompanyYn && <td style={{textAlign:"right"}}><CopyBtn onClick={() => copyText(addCommas(shareholder?.가수금증자.investment/shareholder?.가수금증자.number))} className={st.addressCopyBtn}>{addCommas(shareholder?.가수금증자.investment/shareholder?.가수금증자.number)}</CopyBtn> 원</td>}
                                </tr>
                            }
                        </Fragment>
                    )
                })}
            </tbody>
        </table>
    ));
}
export default CapitalIncreaseStep;