import { useObserver } from 'mobx-react';
import st from '../../AdminFinalCheckView.module.scss';
import clsx from 'clsx';
import {toJS} from "mobx";
import {addCommas, percent} from '@common/module/replaceNumber';
import { Fragment } from 'react';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';
import Icon from '@comComponents/atoms/Icon/Icon';
import vm from './PaidInCapitalIncreaseStepVm';

function PaidInCapitalIncreaseStep(props) {
    return useObserver(() => (
        <>
            <div className={st.row}> 
                <div className={st.dName}>신주발행절차</div>
                {props?.step?.issuingNewShareProcess === null && 
                    <div className={st.data}>
                        -
                    </div>
                }
                {props?.step?.issuingNewShareProcess === "주주_동의서_제출" && 
                    <div className={st.data}>
                        기간단축동의
                    </div>
                }
                {props?.step?.issuingNewShareProcess === "신주발행_주주_통지" && 
                    <div className={st.data}>
                        신주발행통지
                    </div>
                }
                {props?.step?.issuingNewShareProcess === "신주발행_홈페이지_신문사_공고" && 
                    <div className={st.data}>
                        {props?.step?.noticeType === "신문사" ? "신주발행공고(신문사)" : "신주발행공고(홈페이지)"}
                    </div>
                }
            </div>
            <div className={st.row}> 
                <div className={st.dName}>배정방법</div>
                {props?.step?.newShareAssignmentMethod === null && 
                    <div className={st.data}>
                        -
                    </div>
                }
                {props?.step?.newShareAssignmentMethod === "제3자_배정" && 
                    <div className={st.data}>
                        제3자배정
                    </div>
                }
                {props?.step?.newShareAssignmentMethod === "주주_배정" && 
                    <div className={st.data}>
                        주주배정
                    </div>
                }
                {props?.step?.newShareAssignmentMethod === "주주_제3자_혼합" && 
                    <div className={st.data}>
                        주주배정+제3자배정 혼합
                    </div>
                }
            </div>
            {props?.step?.noticeDate && 
                <div className={st.row}> 
                    <div className={st.dName}>
                        {props?.step?.issuingNewShareProcess === "신주발행_주주_통지" && "통지일"}
                        {props?.step?.issuingNewShareProcess === "신주발행_홈페이지_신문사_공고" && "공고일"}
                    </div>
                    <div className={st.data}>{props?.step?.noticeDate}</div>
                </div>
            }
            <table className={st.lightTable} style={{marginTop: '20px'}}>
                <colgroup>
                    <col width="90px"/>
                    <col width="300px"/>
                    <col />
                    <col />
                    <col width="120px"/>
                    <col width="120px"/>
                    <col width="120px"/>
                </colgroup>
                <thead>
                    <tr>
                        <th></th>
                        <th style={{textAlign:"center"}}>인적사항</th>
                        <th style={{textAlign:"center"}}>발행방법</th>
                        <th style={{textAlign:"center"}}>주식종류</th>
                        <th style={{textAlign:"right"}}>투자금</th>
                        <th style={{textAlign:"right"}}>주식수</th>
                        <th style={{textAlign:"right"}}>발행가액<br/>(자동계산)</th>
                    </tr>
                </thead>
                <tbody>
                    {props.step.shareholders.length === 0 &&
                        <tr>
                            <td style={{textAlign:"center"}}>-</td>
                            <td style={{textAlign:"center"}}>-</td>
                            <td style={{textAlign:"center"}}>-</td>
                            <td style={{textAlign:"center"}}>-</td>
                            <td style={{textAlign:"right"}}>-</td>
                            <td style={{textAlign:"right"}}>-</td>
                            <td style={{textAlign:"right"}}>-</td>
                        </tr>
                    }
                    {props.step.shareholders.map((shareholder, index) => (
                        <Fragment key={index}>
                            <tr>
                                <td className={clsx(st.bottom, st.top)} rowSpan={shareholder.가수금증자Stocks.length + shareholder.유상증자Stocks.length+1} style={{paddingLeft:"0"}}>
                                    <div className={st.name}>
                                        <CopyBtn onClick={() => copyText(shareholder.koreanName)} className={st.addressCopyBtn} style={{textAlign:'left'}}>{shareholder.koreanName}</CopyBtn>
                                        {shareholder.englishName && 
                                            <div style={{marginBottom:'2px', fontSize:"13px"}}>
                                                <CopyBtn onClick={() => copyText(shareholder.englishName)} className={st.addressCopyBtn} style={{textAlign:'left'}}>{shareholder.englishName}</CopyBtn>
                                            </div>
                                        }
                                    </div>
                                    <span>{vm.displayExistingShareholdersYn(shareholder.existingShareholdersYn, props?.step?.newShareAssignmentMethod)}</span>
                                </td>
                                <td className={clsx(st.bottom, st.top)} rowSpan={shareholder.가수금증자Stocks.length + shareholder.유상증자Stocks.length+1} style={{textAlign:"center"}}>
                                    {(shareholder.shareholderType === "FOREIGNER_COMPANY" || shareholder.shareholderType === "KOREAN_COMPANY") && 
                                        <div style={{marginBottom:"5px"}}>
                                            {shareholder.representatives.map((representative, index2) => (
                                                <div key={index2}>
                                                    {representative.position} <CopyBtn onClick={() => copyText(representative.koreanName)} className={st.addressCopyBtn}>{representative.koreanName}</CopyBtn>
                                                    {representative.englishName && 
                                                        <span>(<CopyBtn onClick={() => copyText(representative.englishName)} className={st.addressCopyBtn}>{representative.englishName}</CopyBtn>)</span>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {shareholder.shareholderType === "FUND_AND_INVESTMENT_ASSOCIATION" && 
                                        <div style={{marginBottom:"5px"}}>
                                            {shareholder.representatives.map((representative, index2) => (
                                                <div key={index2}>
                                                    대표 <CopyBtn onClick={() => copyText(representative.koreanName)} className={st.addressCopyBtn}>{representative.koreanName}</CopyBtn>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {shareholder.birth && 
                                        <div style={{marginBottom:"5px"}}><CopyBtn onClick={() => copyText(shareholder.birth)} className={st.addressCopyBtn}>{shareholder.birth}</CopyBtn></div>
                                    }
                                    {shareholder.englishName &&
                                        <div style={{marginBottom:"5px"}}>{shareholder.nationality}</div>
                                    }
                                    <div>
                                        {shareholder?.address !== null &&
                                            <CopyBtn onClick={() => copyText(shareholder?.koreanAddress)} className={st.addressCopyBtn}>{shareholder?.koreanAddress}</CopyBtn>
                                        }
                                        {shareholder.englishAddress && 
                                            <div>(<CopyBtn onClick={() => copyText(shareholder.englishAddress)} className={st.addressCopyBtn}>{shareholder.englishAddress}</CopyBtn>)</div>
                                        }
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            {shareholder.유상증자Stocks.map((유상증자Stock, index2) => (
                                <tr key={index2} className={clsx(shareholder.가수금증자Stocks.length + shareholder.유상증자Stocks.length === index2+1 ? st.bottom : null, index2 === 0 ? st.top : null)}>
                                    <td style={{textAlign:"center"}}>{index2===0 && "유상증자"}</td>
                                    <td style={{textAlign:"center"}}>{유상증자Stock.name}</td>
                                    <td style={{textAlign:"right"}}><CopyBtn onClick={() => copyText(addCommas(유상증자Stock.investment))} className={st.addressCopyBtn}>{addCommas(유상증자Stock.investment)}</CopyBtn> 원</td>
                                    <td style={{textAlign:"right"}}><CopyBtn onClick={() => copyText(addCommas(유상증자Stock.number))} className={st.addressCopyBtn}>{addCommas(유상증자Stock.number)}</CopyBtn> 주</td>
                                    <td style={{textAlign:"right"}}><CopyBtn onClick={() => copyText(addCommas(유상증자Stock.investment/유상증자Stock.number))} className={st.addressCopyBtn}>{addCommas(유상증자Stock.investment/유상증자Stock.number)}</CopyBtn> 원</td>
                                </tr>
                            ))}
                            {shareholder.가수금증자Stocks.map((가수금증자Stock, index2) => (
                                <tr key={index2} className={clsx(shareholder.가수금증자Stocks.length === index2+1 ? st.bottom : null, shareholder.유상증자Stocks.length === 0 && index2 === 0 ? st.top : null)}>
                                    <td style={{textAlign:"center"}}>{index2===0 && "가수금증자"}</td>
                                    <td style={{textAlign:"center"}}>{가수금증자Stock.name}</td>
                                    <td style={{textAlign:"right"}}><CopyBtn onClick={() => copyText(addCommas(가수금증자Stock.investment))} className={st.addressCopyBtn}>{addCommas(가수금증자Stock.investment)}</CopyBtn> 원</td>
                                    <td style={{textAlign:"right"}}><CopyBtn onClick={() => copyText(addCommas(가수금증자Stock.number))} className={st.addressCopyBtn}>{addCommas(가수금증자Stock.number)}</CopyBtn> 주</td>
                                    <td style={{textAlign:"right"}}><CopyBtn onClick={() => copyText(addCommas(가수금증자Stock.investment/가수금증자Stock.number))} className={st.addressCopyBtn}>{addCommas(가수금증자Stock.investment/가수금증자Stock.number)}</CopyBtn> 원</td>
                                </tr>
                            ))}
                        </Fragment>
                    ))}
                </tbody>
            </table>
        </>
        
    ));
}
export default PaidInCapitalIncreaseStep;