import StandByStockholderMeetingInfoStepModel from '@model/changeRegistration/StandByStockholderMeetingInfoStepModel';
import stepService from "@service/stepService";
import formDataToJson, { errorFocus, findPos }  from '@common/module/submit';
import { runInAction, observable } from 'mobx';
import portalModel from '@model/portalModel';
import NormalModal from '@pages/ModalContent/changeRegistration/StandByStockholderMeetingParticipant/StockholdersNormalModal';
import SpecialModal from '@pages/ModalContent/changeRegistration/StandByStockholderMeetingParticipant/StockholdersSpecialModal';

class StandByStockholderMeetingParticipantVm {
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error === false) {
            let param = formDataToJson(e.target).data;



            if(this.checkState === "부족") {

                const stockholderMeetingType = StandByStockholderMeetingInfoStepModel.stockholderMeetingType;
                const totalStockAmount = StandByStockholderMeetingInfoStepModel.totalStockAmount; //발행주식 총수
                const participantsTotalAmount = this.participantsTotalAmount; //출석주식 총수

                let agreeAmount = 0; //찬성한 주주의 주식수
                let agreeSealProvidingAmount = 0;

                StandByStockholderMeetingInfoStepModel.participants.forEach((participant) => {
                    if(participant.sealProvidingYn) {
                        participant?.stocks.forEach((stock) => {
                            agreeSealProvidingAmount = agreeSealProvidingAmount + stock.amount;
                        })
                    }
                    if(participant.agreeYn) {
                        participant?.stocks.forEach((stock) => {
                            agreeAmount = agreeAmount + stock.amount;
                        })
                    }
                })
        
                let agreeType = "";

                if(stockholderMeetingType === "주주총회_일반결의") {
                    if(!(participantsTotalAmount/2 < agreeAmount) || !(totalStockAmount/4 <= agreeAmount)) {
                        agreeType = "찬성";
                    } else if(!(participantsTotalAmount/2 < agreeSealProvidingAmount) || !(totalStockAmount/4 <= agreeSealProvidingAmount)) {
                        agreeType = "인감증명서";
                    }
                } else {
                    if(!((participantsTotalAmount/3)*2 <= agreeAmount) || !(totalStockAmount/3 <= agreeAmount)) {
                        agreeType = "찬성";
                    } else if(!((participantsTotalAmount/3)*2 <= agreeSealProvidingAmount) || !(totalStockAmount/3 <= agreeSealProvidingAmount)) {
                        agreeType = "인감증명서";
                    }
                }

                let modal = null;
                if(stockholderMeetingType === '주주총회_일반결의') {
                    modal = <NormalModal agreeType={agreeType}/>; 
                } else {
                    modal = <SpecialModal agreeType={agreeType}/>;
                }

                runInAction(() => {
                    portalModel.modalContent = modal;
                    portalModel.btn = [{title: '확인', type: 'positive', event: ()=> {portalModel.setModalClose()}}];
                    portalModel.closeBtn = true;
                })
                return;
            }
            
            stepService.saveStep(param);
        }
    }
    get participants() {
        return StandByStockholderMeetingInfoStepModel.participants;
    }
    get participantsTotalAmount() {
        let total = 0;
        StandByStockholderMeetingInfoStepModel.participants.forEach((participant) => {
            participant?.stocks.forEach((stock) => {
                total = total + stock.amount;
            })
        })

        return total;
    }
    get totalAmount() {
        return StandByStockholderMeetingInfoStepModel.totalStockAmount;
    }
    rate(idx, idx2) {
        const total = this.totalAmount;
        const amount = StandByStockholderMeetingInfoStepModel.participants[idx].stocks[idx2].amount;

        const rate = ((amount / total) * 100).toFixed(2);

        return rate;
    }
    get agreeAllChecked() {
        let allChecked = true;
        StandByStockholderMeetingInfoStepModel.participants.forEach((participant) => {
            if(participant.agreeYn === false) {
                participant.sealProvidingYn = false;
                allChecked = false;
            }
        })
        return allChecked;
    }
    setAgreeAllChecked(e) {
        runInAction(() => {
            StandByStockholderMeetingInfoStepModel.participants.forEach((participant) => {
                participant.agreeYn = e.target.checked;
            })
        })
    }
    get sealAllChecked() {
        let allChecked = true;
        StandByStockholderMeetingInfoStepModel.participants.forEach((participant) => {
            if(participant.sealProvidingYn === false) {
                allChecked = false;
            }
        })
        return allChecked;
    }
    setSealAllChecked(e) {
        runInAction(() => {
            StandByStockholderMeetingInfoStepModel.participants.forEach((participant) => {
                participant.sealProvidingYn = e.target.checked;
            })
        })
    }
    setAgreeYn(e, idx) {
        runInAction(() => {
            StandByStockholderMeetingInfoStepModel.participants[idx].agreeYn = e.target.checked;
        })
    } 
    setSealProvidingYn(e, idx) {
        runInAction(() => {
            StandByStockholderMeetingInfoStepModel.participants[idx].sealProvidingYn = e.target.checked;
        })
    } 
    get checkState() {
        const stockholderMeetingType = StandByStockholderMeetingInfoStepModel.stockholderMeetingType;
        const totalStockAmount = StandByStockholderMeetingInfoStepModel.totalStockAmount; //발행주식 총수
        let state = "부족";

        const participantsTotalAmount = this.participantsTotalAmount; //출석주식 총수
        let agreeAmount = 0; //출석 주식수
        StandByStockholderMeetingInfoStepModel.participants.forEach((participant) => {
            if(participant.sealProvidingYn) {
                participant?.stocks.forEach((stock) => {
                    agreeAmount = agreeAmount + stock.amount;
                })
            }
        })

        if(stockholderMeetingType === "주주총회_일반결의") {
            if(participantsTotalAmount/2 < agreeAmount) {
                if(totalStockAmount/4 <= agreeAmount) {
                    state = "충분"
                }
            }
        } else {
            if((participantsTotalAmount/3)*2 <= agreeAmount) {
                if(totalStockAmount/3 <= agreeAmount) {
                    state = "충분"
                }
            }
        }
        return state;
    }
}

export default new StandByStockholderMeetingParticipantVm();